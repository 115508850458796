// Remix
import type {
  LinksFunction,
  LoaderFunction,
  MetaFunction,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
} from "@remix-run/react";
// Styles
import tailwindStylesheetUrl from "./tailwind/tailwind.css";
import fontsStyleSheetUrl from "../styles/fonts.css";
// Providers & Hooks
import { i18n } from "~/i18n.server";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next";
import { getEnv } from "./env";
import { supportedLanguages } from "./i18n-config";
// Environment Variables
import type { EnvironmentVariables } from "./env";
import { getClientEnvironmentVariables } from "./env";
import { EnvProvider } from "./env";
// Components
import { Nav } from "~/components/Nav";
import { GoogleAnalytics } from "~/components/GoogleAnalytics";
import { Footer } from "~/components/Footer";

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: tailwindStylesheetUrl },
    { rel: "stylesheet", href: fontsStyleSheetUrl },
    { rel: "shortcut icon", href: "/favicon.svg" },
  ];
};

export const meta: MetaFunction = () => {
  const title =
    "Website accelerator | Custom development in a dash | DEPT DASH™";
  const description =
    "Websites take forever to build. That's why we created DEPT DASH™, a website accelerator for marketing and e-commerce websites.";
  const image = "https://dash-marketing.fly.dev/og-image.png";

  return {
    charset: "utf-8",
    title,
    description,
    keywords: "DEPT,blog,store",
    "twitter:creator": "@deptagency",
    "twitter:site": "@deptagency",
    "twitter:title": title,
    "twitter:description": description,
    "twitter:image": image,
    "twitter:card": "summary_large_image",
    "og:title": title,
    "og:description": description,
    "og:image": image,
    "og:image:width": "1200",
    "og:image:height": "630",
    "og:image:alt": "DEPT DASH™",
    "og:type": "website",
    "og:site_name": "DEPT DASH™",
    viewport: "width=device-width,initial-scale=1",
  };
};

type LoaderData = {
  GLOBALS: string;
  locale: string;
  environmentVariables: EnvironmentVariables;
};

export const loader: LoaderFunction = async ({ request }) => {
  const locale = await i18n.getLocale(request);
  return json<LoaderData>({
    locale,
    environmentVariables: getClientEnvironmentVariables(),
    GLOBALS: JSON.stringify({
      SENTRY_DSN: getEnv("SENTRY_DSN", { default: null }),
    }),
  });
};
/**
 * Construct <link rel="alternate"> tags to inform search engines and browsers
 * about locale variants of the page, as described at https://developers.google.com/search/docs/advanced/crawling/localized-versions#html
 */
function useAlternateLinks() {
  const { locale, environmentVariables } = useLoaderData<LoaderData>();
  const { pathname, search } = useLocation();
  const PUBLICLY_AVAILABLE_ORIGIN =
    environmentVariables["PUBLICLY_AVAILABLE_ORIGIN"];
  if (!PUBLICLY_AVAILABLE_ORIGIN) return [];
  const links = supportedLanguages
    .filter((lang) => lang.code !== locale) // skip current locale
    .map((lang) => (
      <link
        key={lang.code}
        rel="alternate"
        hrefLang={lang.code}
        href={`https://${
          new Intl.Locale(lang.code).language
        }.${PUBLICLY_AVAILABLE_ORIGIN}${pathname}${search}`}
      />
    ));
  links.push(
    <link
      key="x-default"
      rel="alternate"
      hrefLang="x-default"
      href={`https://${PUBLICLY_AVAILABLE_ORIGIN}${pathname}${search}`}
    />
  );
  return links;
}

export const handle = {
  // In the handle export, we could add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  i18n: [],
};

export default function App() {
  let { locale, environmentVariables, GLOBALS } = useLoaderData<LoaderData>();
  let { i18n } = useTranslation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  const alternateLinks = useAlternateLinks();

  return (
    <html
      lang={locale}
      className="h-full w-screen overflow-x-hidden"
      dir={i18n.dir()}
    >
      <head>
        <Meta />
        <Links />
        {alternateLinks}
        <GoogleAnalytics />
      </head>

      <EnvProvider environmentVariables={environmentVariables}>
        <body className="max-w-screen h-full w-screen overflow-x-hidden">
          <Nav sticky />
          <main id="main-content">
            <Outlet />
          </main>

          <Footer />

          <ScrollRestoration />
          <script
            suppressHydrationWarning
            dangerouslySetInnerHTML={{ __html: `window.GLOBALS=${GLOBALS}` }}
          />
          <Scripts />
          <LiveReload />
        </body>
      </EnvProvider>
    </html>
  );
}
