import { Button } from "./Button";

const social = [
  {
    name: "facebook",
    href: "https://www.facebook.com/DeptAgency/",
    icon: (props: React.SVGProps<SVGSVGElement>) => (
      <svg
        fill="##fff"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="50px"
        height="50px"
        {...props}
      >
        <path
          d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"
          fill="#fff"
        />
      </svg>
    ),
  },
  {
    name: "twitter",
    href: "https://twitter.com/deptagency",
    icon: (props: React.SVGProps<SVGSVGElement>) => (
      <svg
        width="36"
        height="28"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M18 1.65302C17.3263 1.93799 16.6118 2.12508 15.8804 2.20813C16.6506 1.76756 17.2267 1.07346 17.5008 0.255727C16.7766 0.667468 15.984 0.95726 15.1574 1.1125C14.6505 0.59702 13.9936 0.239032 13.2717 0.0848694C12.5498 -0.0692931 11.7962 -0.0125323 11.1084 0.247804C10.4206 0.50814 9.83031 0.960065 9.41388 1.54509C8.99745 2.13011 8.77408 2.82129 8.77269 3.52917C8.77159 3.79981 8.80344 4.06965 8.8676 4.33323C7.39894 4.26297 5.96207 3.89824 4.65007 3.26267C3.33806 2.6271 2.18017 1.73486 1.2514 0.643739C0.780498 1.42222 0.636762 2.34303 0.849372 3.21924C1.06198 4.09545 1.615 4.86139 2.39617 5.36158C1.80902 5.34405 1.23466 5.19298 0.720609 4.92086V4.96347C0.721106 5.77961 1.01658 6.57046 1.55691 7.20185C2.09723 7.83324 2.84914 8.2663 3.68507 8.42756C3.36759 8.51153 3.03957 8.55341 2.71019 8.55204C2.47197 8.55368 2.23415 8.53302 2.00013 8.49036C2.23506 9.19317 2.69411 9.80789 3.31282 10.2482C3.93153 10.6885 4.6788 10.9322 5.44968 10.9452C3.90439 12.0991 1.94713 12.6255 0 12.4109C1.58607 13.3843 3.4167 13.9311 5.29972 13.9939C7.18274 14.0567 9.0487 13.6332 10.7017 12.7678C12.3548 11.9025 13.7339 10.6271 14.6944 9.07575C15.6549 7.52436 16.1613 5.75411 16.1604 3.95083V3.47198C16.8814 2.97477 17.5042 2.35887 18 1.65302"
          fill="#FFF"
        />
      </svg>
    ),
  },
  {
    name: "linkedin",
    href: "https://www.linkedin.com/company/deptagency/",
    icon: (props: React.SVGProps<SVGSVGElement>) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 24 24"
        {...props}
      >
        <path
          d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
          fill="#FFF"
        />
      </svg>
    ),
  },
];

export function Footer() {
  return (
    <footer className="flex h-32 flex-col justify-between bg-primary px-[30px] md:flex-row lg:px-[60px]">
      <div className="mt-4 flex flex-row items-center justify-start text-white md:mt-0 md:flex-col md:justify-center">
        ADA/DASH &nbsp;&copy; <CurrentYear /> Dept Technology LLC <br /> All
        Rights Reserved
      </div>
      <div className="flex h-full items-center justify-start gap-6 md:justify-end">
        {social.map((item) => (
          <Button
            variant="tertiary"
            key={item.name}
            href={item.href}
            as="a"
            icon={<item.icon aria-hidden="true" className="h-6 w-6" />}
            rounded
            className="bg-primary px-0 hover:bg-transparent hover:opacity-75"
          ></Button>
        ))}
      </div>
    </footer>
  );
}

function CurrentYear() {
  const year = new Date().getFullYear();
  return <>{year}</>;
}
