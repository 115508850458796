import React from "react";
import type { ReactNode } from "react";
import { useContext } from "react";
import { createContext } from "react";

// Environment variable names to load into the client for retrieval with useEnv()
// DO NOT place sensitive environment variables in here, since they'll all be (intentionally) leaked to the client
// TODO: Should we move this to root to make it more discoverable?
const CLIENT_ENVIRONMENT_VARIABLES = [
  {
    key: "PUBLICLY_AVAILABLE_HOSTNAME",
    options: {
      default: null,
    },
  },
  {
    key: "GA_TRACKING_ID",
    options: {
      default: null,
    },
  },
];

export type EnvironmentVariables = { [key: string]: string | null };

export const EnvContext = createContext<EnvironmentVariables | null>(null);

export type EnvProviderProps = {
  environmentVariables: EnvironmentVariables;
  children: ReactNode;
};

export const EnvProvider = ({
  environmentVariables,
  children,
}: EnvProviderProps) => {
  return (
    <EnvContext.Provider value={environmentVariables}>
      {children}
    </EnvContext.Provider>
  );
};

export function getClientEnvironmentVariables(): EnvironmentVariables {
  const environmentVariables: EnvironmentVariables = {};
  for (const envVar of CLIENT_ENVIRONMENT_VARIABLES) {
    if (envVar.options) {
      environmentVariables[envVar.key] = getEnv(envVar.key, envVar.options);
    } else {
      environmentVariables[envVar.key] = getEnv(envVar.key);
    }
  }
  return environmentVariables;
}

export function useEnv(key: string): string {
  const env = useContext(EnvContext);
  if (!env) {
    throw new Error(`useEnv was called without an EnvContext for key: ${key}`);
  }
  if (!env[key]) {
    throw new Error(
      "useEnv tried to retrieve environment variable that isn't set. Did you forget to add it to CLIENT_ENVIRONMENT_VARIABLES?"
    );
  }
  // It's safe to assume this is a string since we just checked if it's null
  return env[key]!;
}

export function useOptionalEnv(key: string): string | null {
  const env = useContext(EnvContext);
  if (!env) {
    throw new Error(`useEnv was called without an EnvContext for key: ${key}`);
  }
  // Intentionally only check for undefined, because it's valid to have a null value (usually for known environment variables that default to null)
  if (env[key] === undefined) {
    throw new Error(
      "useEnv tried to retrieve environment variable that doesn't exist"
    );
  }
  return env[key];
}

export function getEnv(property: string): string;
export function getEnv<T>(
  property: string,
  options: { default: T }
): string | T;
export function getEnv(
  property: string,
  options?: { default: string | null }
): string | null {
  const value = process.env[property];
  if (!value && options) return options.default;
  if (!value)
    throw new Error(
      `no environment variable found for "${property}" and no default provided`
    );
  return value;
}
