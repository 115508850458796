import { Button } from "./Button";
import { AlertBanner } from "./AlertBanner";
import logoImage from "./stories/assets/logo.svg";
import { NavLink } from "@remix-run/react";

export type NavProps = {
  sticky?: boolean;
  banner?: React.ReactNode; // slot at the top of the navbar.
  slot?: React.ReactNode; //
  children?: React.ReactNode; // slot at the bottom of the nav bar
};

export function Nav({ sticky = false, banner, slot, children }: NavProps) {
  const links = [
    { name: "Intro", href: "#intro" },
    { name: "Benefits", href: "#benefits" },
    { name: "Features", href: "#features" },
    { name: "Case Studies", href: "#case-studies" },
  ];

  return (
    <nav
      id="nav-header"
      className={`${sticky ? "sticky top-0 z-50" : ""} bg-white`}
    >
      {banner && <AlertBanner content={banner} />}
      <div className="contained-width flex h-[80px] items-center justify-between p-2 px-10">
        <div className="leading-[64px]">
          <NavLink to="/">
            <img
              src="/logo.png"
              alt="Logo"
              className="inline-block h-[30px] w-[176px]"
              width="194px"
              height="22px"
            />
          </NavLink>
        </div>
        <div className="flex items-center gap-10">
          <a href="#main-content" className="sr-only focus:not-sr-only">
            Skip Navigation
          </a>
          {links.map(({ name, href }) => (
            <a
              key={name}
              href={href}
              className={`group hidden text-text transition duration-500 lg:inline`}
            >
              {name}
              <span className="block h-[2px] max-w-0 bg-primary transition-all duration-500 group-hover:max-w-full"></span>
            </a>
          ))}
          <div className="ml-10">{slot}</div>
        </div>
        <Button
          rounded
          href="https://www.deptagency.com/en-us/contact/"
          className="inline-block"
          aria-label="Contact Us"
          as="a"
        >
          Contact Us
        </Button>
      </div>

      {children}
    </nav>
  );
}
